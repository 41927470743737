import {MutationTree} from 'vuex';
import {MapState} from '@store/modules/map/interfaces/MapState';

export const mapMutations: MutationTree<MapState> = {
    setMaps(state, maps) {
        state.maps = maps;
    },
    setMap(state, id) {
        state.map = state.maps.find(({id: mapId}) => mapId === id) ?? state.map;
    },
    setGalleries(state, galleries) {
        state.galleries = galleries;
    },
    changeLang(state) {
        if (state.lang === 'ru') {
            state.lang = 'en';
        } else {
            state.lang = 'ru';
        }
    },
    setMapObjectPreviewContent(state, data) {
        state.mapObjectPreview = data;
    },
    clearMapObjectPreviewContent(state) {
        state.mapObjectPreview = {
            title: '',
            coordinates: '',
            textPreview: '',
            image: '',
        };
    },
    showMapObjectPreview(state) {
        state.mapObjectPreviewVisible = true;
    },
    hideMapObjectPreview(state) {
        state.mapObjectPreviewVisible = false;
    },
    setMapPopupContent(state, data) {
        state.mapPopup = data;
    },
    clearMapPopupContent(state) {
        state.mapPopup = {
            title: '',
            coordinates: '',
            link: '',
            author: '',
            image: '',
            text: '',
        };
    },
    showMapPopup(state) {
        state.mapPopupVisible = true;
    },
    hideMapPopup(state) {
        state.mapPopupVisible = false;
    },
    setVectorPlotData(state, data) {
        state.vectorPlotData = data;
    },
};
