import {createRouter, createWebHistory} from 'vue-router';
import {routes} from '@/router/routes';

const router = createRouter({
    scrollBehavior() {
        return {left: 0, top: 0};
    },
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
