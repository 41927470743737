// import VueWait from 'vue-wait';
import Highcharts from 'highcharts';
import HighchartsStock from 'highcharts/highstock';
import HighchartsVue from 'highcharts-vue';
import Heatmap from 'highcharts/modules/heatmap';
import Vector from 'highcharts/modules/vector';
import Annotations from 'highcharts/modules/annotations';
// @ts-ignore
import HollowCandlestick from 'highcharts/modules/hollowcandlestick';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts3D from 'highcharts/highcharts-3d';
//
// Vue.config.productionTip = false;
//
// Vue.use(VueWait);
//
// new Vue({
//     store,
//     router,
//     wait: new VueWait({ useVuex: true }),
//     render: h => h(App)
// }).$mount('#app');

import {createApp} from 'vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import '@styles/index.styl';

Heatmap(Highcharts);
Vector(Highcharts);
Annotations(HighchartsStock);
HollowCandlestick(HighchartsStock);
HighchartsMore(Highcharts);
Highcharts3D(Highcharts);

createApp(App)
    .use(PerfectScrollbar)
    // @ts-ignore
    .use(HighchartsVue, {tagName: 'chart'})
    .use(store)
    .use(router)
    .mount('#app');
