import {RouteRecordRaw} from 'vue-router';

const MainPage = () => import('@components/MainPage.vue');
const AboutPage = () => import('@components/AboutPage.vue');
const HubsPage = () => import('@components/HubsPage.vue');
const MapPage = () => import('@components/MapPage.vue');
const CustomMaps = () => import('@components/CustomMaps.vue');
const MapFull = () => import('@components/MapFull.vue');
const PhotoGallery = () => import('@components/PhotoGallery.vue');
const VideoGallery = () => import('@components/VideoGallery.vue');
const TextGallery = () => import('@components/TextGallery.vue');

const ConnectionsData = () => import('@components/ConnectionsData.vue');
const ConnectionsObjects = () => import('@components/ConnectionsObjects.vue');
const ConnectionsConnections = () => import('@components/ConnectionsConnections.vue');
const ConnectionsTotalEconomic = () => import('@components/ConnectionsTotalEconomic.vue');
const ConnectionsCode = () => import('@components/ConnectionsCode.vue');

export const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'main',
        component: MainPage,
    },
    {
        path: '/about',
        name: 'about',
        component: AboutPage,
    },
    {
        path: '/heracleum',
        children: [
            {
                path: '',
                name: 'connections',
                component: HubsPage,
            },
            {
                path: 'data',
                name: 'connections-data',
                component: ConnectionsData,
            },
            {
                path: 'objects',
                name: 'connections-objects',
                component: ConnectionsObjects,
            },
            {
                path: 'connections',
                name: 'connections-connections',
                component: ConnectionsConnections,
            },
            {
                path: 'total-economic',
                name: 'connections-total-economic',
                component: ConnectionsTotalEconomic,
            },
            {
                path: 'code',
                name: 'connections-code',
                component: ConnectionsCode,
            },
        ],
    },
    {
        path: '/map/:mapId',
        children: [
            {
                path: '',
                name: 'map',
                component: MapPage,
            },
            {
                path: 'full',
                name: 'full',
                component: MapFull,
            },
            {
                path: 'custom',
                name: 'custom',
                component: CustomMaps,
            },
            {
                path: 'photo',
                name: 'photo',
                component: PhotoGallery,
            },
            {
                path: 'video',
                name: 'video',
                component: VideoGallery,
            },
            {
                path: 'texts',
                name: 'texts',
                component: TextGallery,
            },
        ],
    },
];
