import {MapState, Gallery} from '@store/modules/map/interfaces/MapState';
import {Map} from '@interfaces/Map';
import {GetterTree} from 'vuex';
import {RootState} from '@store/interfaces/RootState';

export const mapGetters: GetterTree<MapState, RootState> = {
    map: (state: MapState) => state.map,
    isMapWorld: (state) => state.map.id === 9,
    allMaps: (state) => state.maps,
    parentMaps: (state, getters) => state.maps
        .filter((childMap) => (getters.map?.parent_id
            ? childMap.parent_id === getters.map.parent_id
            : !childMap.parent_id)),
    customMaps: (state, getters) => getters.allMaps.filter((item: Map) => item.parent_id === getters.map.id),
    galleries: (state) => state.galleries,
    photoGalleries: (state) => state.galleries.filter((gallery) => gallery.gallery_types_id === 2),
    photos: (state, getters) => getters.photoGalleries.map((gallery: Gallery) => gallery.assets).flat(),
    videoGalleries: (state) => state.galleries.filter((gallery) => gallery.gallery_types_id === 1),
    videos: (state, getters) => getters.videoGalleries.map((gallery: Gallery) => gallery.assets).flat(),
    textGalleries: (state) => state.galleries.filter((gallery) => gallery.gallery_types_id === 3),
    texts: (state, getters) => getters.textGalleries.map((gallery: Gallery) => gallery.assets).flat(),
};
