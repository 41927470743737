
import {
    defineComponent,
    onMounted,
    computed,
    ref,
    unref,
} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {getGalleries, loadMaps} from '@api/map';

export default defineComponent({
    name: 'App',
    setup() {
        // todo: нужно ли?
        document.title = 'ferations.world';

        const route = useRoute();
        const store = useStore();

        const isLoading = ref(true);

        const currentMapId = computed(() => Number(route.params.mapId));

        const chooseMap = async (mapId: number) => {
            if (mapId) {
                store.commit('map/setMap', mapId);
                const {data: galleries} = await getGalleries(mapId);
                store.commit('map/setGalleries', galleries);
            }
        };

        onMounted(async () => {
            const {data} = await loadMaps();
            store.commit('map/setMaps', data);
            await chooseMap(unref(currentMapId));
            isLoading.value = false;
        });

        return {
            isLoading,
        };
    },
});
