import {mapState} from '@store/modules/map/state';
import {mapGetters} from '@store/modules/map/getters';
import {mapActions} from '@store/modules/map/actions';
import {mapMutations} from '@store/modules/map/mutations';
import {Module} from 'vuex';
import {MapState} from '@store/modules/map/interfaces/MapState';
import {RootState} from '@store/interfaces/RootState';

export const map: Module<MapState, RootState> = {
    namespaced: true,
    state: mapState,
    getters: mapGetters,
    actions: mapActions,
    mutations: mapMutations,
};
