import {MapState} from '@store/modules/map/interfaces/MapState';

export const mapState: MapState = {
    maps: [],
    // @ts-ignore
    map: {},
    galleries: [],
    lang: 'en',
    mapPopupVisible: false,
    mapObjectPreviewVisible: false,
    mapPopup: {
        title: '',
        coordinates: '',
        link: '',
        author: '',
        image: '',
        text: '',
        videoLink: '',
    },
    mapObjectPreview: {
        title: '',
        coordinates: '',
        textPreview: '',
        image: '',
        position: '',
    },
    vectorPlotData: [
        [46.5, 38, 7.12, 29],
        [46.5, 38.1, 6.79, 28],
        [46.5, 38.2, 6.47, 27],
        [46.5, 38.3, 6.26, 26],
        [46.5, 38.4, 6.14, 25],
        [46.5, 38.5, 6.03, 25],
        [46.5, 38.6, 5.99, 23],
        [46.5, 38.7, 5.96, 22],
        [46.5, 38.8, 5.92, 21],
        [46.5, 38.9, 5.9, 18],
        [46.5, 39, 5.88, 16],
        [46.5, 39.1, 5.77, 15],
        [46.5, 39.2, 5.66, 13],
        [46.5, 39.3, 5.6, 13],
        [46.5, 39.4, 5.58, 14],
        [46.5, 39.5, 5.56, 15],
        [46.5, 39.6, 5.64, 15],
        [46.5, 39.7, 5.72, 15],
        [46.5, 39.8, 5.79, 15],
        [46.5, 39.9, 5.86, 14],
        [46.6, 38, 8.38, 29],
        [46.6, 38.1, 8.08, 28],
        [46.6, 38.2, 7.79, 28],
        [46.6, 38.3, 7.47, 27],
        [46.6, 38.4, 7.1, 26],
        [46.6, 38.5, 6.74, 26],
        [46.6, 38.6, 6.5, 25],
        [46.6, 38.7, 6.27, 24],
        [46.6, 38.8, 6.11, 23],
        [46.6, 38.9, 6.04, 21],
        [46.6, 39, 5.97, 19],
        [46.6, 39.1, 5.87, 17],
        [46.6, 39.2, 5.76, 15],
        [46.6, 39.3, 5.7, 14],
        [46.6, 39.4, 5.67, 15],
        [46.6, 39.5, 5.63, 16],
        [46.6, 39.6, 5.71, 15],
        [46.6, 39.7, 5.78, 15],
        [46.6, 39.8, 5.85, 15],
        [46.6, 39.9, 5.9, 14],
        [46.7, 38, 9.64, 29],
        [46.7, 38.1, 9.38, 29],
        [46.7, 38.2, 9.12, 29],
        [46.7, 38.3, 8.68, 28],
        [46.7, 38.4, 8.07, 27],
        [46.7, 38.5, 7.45, 27],
        [46.7, 38.6, 7.02, 26],
        [46.7, 38.7, 6.58, 26],
        [46.7, 38.8, 6.31, 25],
        [46.7, 38.9, 6.19, 24],
        [46.7, 39, 6.08, 22],
        [46.7, 39.1, 5.97, 19],
        [46.7, 39.2, 5.87, 17],
        [46.7, 39.3, 5.8, 16],
        [46.7, 39.4, 5.75, 16],
        [46.7, 39.5, 5.7, 16],
        [46.7, 39.6, 5.77, 16],
        [46.7, 39.7, 5.84, 16],
        [46.7, 39.8, 5.9, 15],
        [46.7, 39.9, 5.95, 14],
        [46.8, 38, 10.06, 28],
        [46.8, 38.1, 9.9, 28],
        [46.8, 38.2, 9.75, 28],
        [46.8, 38.3, 9.38, 27],
        [46.8, 38.4, 8.81, 27],
        [46.8, 38.5, 8.24, 26],
        [46.8, 38.6, 7.78, 26],
        [46.8, 38.7, 7.33, 26],
        [46.8, 38.8, 7.02, 25],
        [46.8, 38.9, 6.85, 24],
        [46.8, 39, 6.68, 23],
        [46.8, 39.1, 6.46, 21],
        [46.8, 39.2, 6.25, 19],
        [46.8, 39.3, 6.06, 17],
        [46.8, 39.4, 5.87, 16],
        [46.8, 39.5, 5.69, 16],
        [46.8, 39.6, 5.77, 16],
        [46.8, 39.7, 5.85, 16],
        [46.8, 39.8, 5.92, 16],
        [46.8, 39.9, 5.97, 15],
        [46.9, 38, 9.65, 26],
        [46.9, 38.1, 9.68, 26],
        [46.9, 38.2, 9.7, 26],
        [46.9, 38.3, 9.59, 25],
        [46.9, 38.4, 9.34, 25],
        [46.9, 38.5, 9.1, 24],
        [46.9, 38.6, 8.8, 24],
        [46.9, 38.7, 8.51, 24],
        [46.9, 38.8, 8.24, 24],
        [46.9, 38.9, 8, 24],
        [46.9, 39, 7.76, 24],
        [46.9, 39.1, 7.33, 22],
        [46.9, 39.2, 6.9, 20],
        [46.9, 39.3, 6.47, 18],
        [46.9, 39.4, 6.03, 17],
        [46.9, 39.5, 5.58, 16],
        [46.9, 39.6, 5.7, 16],
        [46.9, 39.7, 5.82, 17],
        [46.9, 39.8, 5.91, 17],
        [46.9, 39.9, 5.97, 15],
        [47, 38, 9.25, 24],
        [47, 38.1, 9.46, 24],
        [47, 38.2, 9.67, 23],
        [47, 38.3, 9.81, 23],
        [47, 38.4, 9.89, 23],
        [47, 38.5, 9.96, 23],
        [47, 38.6, 9.83, 23],
        [47, 38.7, 9.7, 23],
        [47, 38.8, 9.47, 23],
        [47, 38.9, 9.16, 23],
        [47, 39, 8.84, 24],
        [47, 39.1, 8.2, 23],
        [47, 39.2, 7.56, 21],
        [47, 39.3, 6.89, 20],
        [47, 39.4, 6.18, 18],
        [47, 39.5, 5.48, 16],
        [47, 39.6, 5.63, 17],
        [47, 39.7, 5.79, 18],
        [47, 39.8, 5.9, 17],
        [47, 39.9, 5.96, 16],
        [47.1, 38, 7.73, 23],
        [47.1, 38.1, 7.91, 22],
        [47.1, 38.2, 8.09, 22],
        [47.1, 38.3, 8.22, 21],
        [47.1, 38.4, 8.32, 21],
        [47.1, 38.5, 8.41, 21],
        [47.1, 38.6, 8.31, 21],
        [47.1, 38.7, 8.21, 20],
        [47.1, 38.8, 8.15, 20],
        [47.1, 38.9, 8.12, 20],
        [47.1, 39, 8.08, 20],
        [47.1, 39.1, 7.6, 20],
        [47.1, 39.2, 7.11, 19],
        [47.1, 39.3, 6.62, 18],
        [47.1, 39.4, 6.11, 17],
        [47.1, 39.5, 5.61, 16],
        [47.1, 39.6, 5.66, 16],
        [47.1, 39.7, 5.72, 17],
        [47.1, 39.8, 5.77, 17],
        [47.1, 39.9, 5.82, 16],
        [47.2, 38, 6.22, 21],
        [47.2, 38.1, 6.36, 20],
        [47.2, 38.2, 6.51, 19],
        [47.2, 38.3, 6.64, 19],
        [47.2, 38.4, 6.75, 18],
        [47.2, 38.5, 6.87, 18],
        [47.2, 38.6, 6.81, 18],
        [47.2, 38.7, 6.75, 17],
        [47.2, 38.8, 6.85, 16],
        [47.2, 38.9, 7.11, 16],
        [47.2, 39, 7.37, 16],
        [47.2, 39.1, 7.03, 16],
        [47.2, 39.2, 6.68, 16],
        [47.2, 39.3, 6.36, 16],
        [47.2, 39.4, 6.04, 16],
        [47.2, 39.5, 5.73, 15],
        [47.2, 39.6, 5.69, 16],
        [47.2, 39.7, 6.71, 4],
        [47.2, 39.8, 6.71, 4],
        [47.2, 39.9, 5.67, 16],
        [47.3, 38, 5.27, 20],
        [47.3, 38.1, 5.41, 19],
        [47.3, 38.2, 5.54, 17],
        [47.3, 38.3, 5.66, 16],
        [47.3, 38.4, 5.77, 16],
        [47.3, 38.5, 5.88, 16],
        [47.3, 38.6, 5.86, 15],
        [47.3, 38.7, 5.84, 14],
        [47.3, 38.8, 6, 13],
        [47.3, 38.9, 6.32, 13],
        [47.3, 39, 6.65, 13],
        [47.3, 39.1, 6.44, 13],
        [47.3, 39.2, 6.22, 14],
        [47.3, 39.3, 6.02, 14],
        [47.3, 39.4, 5.85, 14],
        [47.3, 39.5, 5.68, 15],
        [47.3, 39.6, 5.63, 15],
        [47.3, 39.7, 6.71, 4],
        [47.3, 39.8, 6.71, 4],
        [47.3, 39.9, 5.57, 15],
        [47.4, 38, 4.9, 19],
        [47.4, 38.1, 5.03, 18],
        [47.4, 38.2, 5.16, 16],
        [47.4, 38.3, 5.27, 15],
        [47.4, 38.4, 5.36, 15],
        [47.4, 38.5, 5.45, 14],
        [47.4, 38.6, 5.46, 13],
        [47.4, 38.7, 5.46, 12],
        [47.4, 38.8, 5.56, 12],
        [47.4, 38.9, 5.73, 12],
        [47.4, 39, 5.9, 12],
        [47.4, 39.1, 5.8, 12],
        [47.4, 39.2, 5.7, 13],
        [47.4, 39.3, 5.62, 13],
        [47.4, 39.4, 5.54, 13],
        [47.4, 39.5, 5.47, 14],
        [47.4, 39.6, 5.48, 14],
        [47.4, 39.7, 5.49, 14],
        [47.4, 39.8, 4, 10],
        [47.4, 39.9, 4, 10],
        [47.5, 38, 4.52, 18],
        [47.5, 38.1, 4.65, 17],
        [47.5, 38.2, 4.79, 15],
        [47.5, 38.3, 4.89, 14],
        [47.5, 38.4, 4.96, 13],
        [47.5, 38.5, 5.03, 12],
        [47.5, 38.6, 5.06, 11],
        [47.5, 38.7, 5.09, 11],
        [47.5, 38.8, 5.12, 10],
        [47.5, 38.9, 5.14, 11],
        [47.5, 39, 5.15, 11],
        [47.5, 39.1, 5.17, 11],
        [47.5, 39.2, 5.19, 12],
        [47.5, 39.3, 5.21, 12],
        [47.5, 39.4, 5.23, 12],
        [47.5, 39.5, 5.25, 13],
        [47.5, 39.6, 5.33, 12],
        [47.5, 39.7, 4, 10],
        [47.5, 39.8, 4, 10],
        [47.5, 39.9, 4, 10],
        [47.6, 38, 4.33, 18],
        [47.6, 38.1, 4.47, 16],
        [47.6, 38.2, 4.61, 15],
        [47.6, 38.3, 4.72, 14],
        [47.6, 38.4, 4.78, 13],
        [47.6, 38.5, 4.84, 13],
        [47.6, 38.6, 4.88, 12],
        [47.6, 38.7, 4.92, 11],
        [47.6, 38.8, 4.97, 11],
        [47.6, 38.9, 5.03, 11],
        [47.6, 39, 5.09, 12],
        [47.6, 39.1, 5.13, 12],
        [47.6, 39.2, 5.17, 12],
        [47.6, 39.3, 5.21, 12],
        [47.6, 39.4, 5.25, 12],
        [47.6, 39.5, 5.3, 11],
        [47.6, 39.6, 5.37, 11],
        [47.6, 39.7, 5.45, 11],
        [47.6, 39.8, 4, 10],
        [47.6, 39.9, 4, 10],
        [47.7, 38, 4.14, 18],
        [47.7, 38.1, 4.29, 16],
        [47.7, 38.2, 4.44, 15],
        [47.7, 38.3, 4.55, 14],
        [47.7, 38.4, 4.6, 14],
        [47.7, 38.5, 4.65, 13],
        [47.7, 38.6, 4.7, 12],
        [47.7, 38.7, 4.75, 12],
        [47.7, 38.8, 4.82, 12],
        [47.7, 38.9, 4.93, 12],
        [47.7, 39, 5.04, 13],
        [47.7, 39.1, 5.09, 12],
        [47.7, 39.2, 5.15, 12],
        [47.7, 39.3, 5.21, 11],
        [47.7, 39.4, 5.28, 11],
        [47.7, 39.5, 5.35, 10],
        [47.7, 39.6, 5.42, 10],
        [47.7, 39.7, 5.49, 11],
        [47.7, 39.8, 5.57, 10],
        [47.7, 39.9, 5.65, 10],
        [47.8, 38, 4, 17],
        [47.8, 38.1, 4.09, 16],
        [47.8, 38.2, 4.18, 15],
        [47.8, 38.3, 4.25, 14],
        [47.8, 38.4, 4.28, 13],
        [47.8, 38.5, 4.31, 12],
        [47.8, 38.6, 4.43, 12],
        [47.8, 38.7, 4.55, 12],
        [47.8, 38.8, 4.68, 13],
        [47.8, 38.9, 4.82, 13],
        [47.8, 39, 4.96, 13],
        [47.8, 39.1, 5.05, 13],
        [47.8, 39.2, 5.14, 12],
        [47.8, 39.3, 5.24, 12],
        [47.8, 39.4, 5.35, 11],
        [47.8, 39.5, 5.47, 11],
        [47.8, 39.6, 5.55, 11],
        [47.8, 39.7, 5.63, 11],
        [47.8, 39.8, 5.71, 11],
        [47.8, 39.9, 5.79, 10],
        [47.9, 38, 3.89, 17],
        [47.9, 38.1, 3.86, 15],
        [47.9, 38.2, 3.83, 13],
        [47.9, 38.3, 3.82, 12],
        [47.9, 38.4, 3.82, 11],
        [47.9, 38.5, 3.82, 10],
        [47.9, 38.6, 4.07, 11],
        [47.9, 38.7, 4.32, 12],
        [47.9, 38.8, 4.52, 13],
        [47.9, 38.9, 4.69, 14],
        [47.9, 39, 4.86, 14],
        [47.9, 39.1, 5, 14],
        [47.9, 39.2, 5.15, 14],
        [47.9, 39.3, 5.31, 14],
        [47.9, 39.4, 5.49, 14],
        [47.9, 39.5, 5.67, 13],
        [47.9, 39.6, 5.77, 13],
        [47.9, 39.7, 5.87, 12],
        [47.9, 39.8, 5.94, 11],
        [47.9, 39.9, 5.98, 10],
        [48, 38, 3.78, 16],
        [48, 38.1, 3.63, 14],
        [48, 38.2, 3.49, 11],
        [48, 38.3, 3.4, 9],
        [48, 38.4, 3.37, 9],
        [48, 38.5, 3.34, 8],
        [48, 38.6, 3.71, 10],
        [48, 38.7, 4.09, 12],
        [48, 38.8, 4.37, 13],
        [48, 38.9, 4.56, 14],
        [48, 39, 4.76, 15],
        [48, 39.1, 4.96, 15],
        [48, 39.2, 5.16, 16],
        [48, 39.3, 5.39, 16],
        [48, 39.4, 5.63, 16],
        [48, 39.5, 5.88, 16],
        [48, 39.6, 5.99, 14],
        [48, 39.7, 6.1, 13],
        [48, 39.8, 6.17, 12],
        [48, 39.9, 6.18, 11],
        [48.1, 38, 3.46, 11],
        [48.1, 38.1, 3.39, 10],
        [48.1, 38.2, 3.32, 9],
        [48.1, 38.3, 3.28, 8],
        [48.1, 38.4, 3.29, 7],
        [48.1, 38.5, 3.3, 6],
        [48.1, 38.6, 3.56, 8],
        [48.1, 38.7, 3.82, 10],
        [48.1, 38.8, 4.06, 11],
        [48.1, 38.9, 4.26, 13],
        [48.1, 39, 4.46, 14],
        [48.1, 39.1, 4.68, 14],
        [48.1, 39.2, 4.9, 14],
        [48.1, 39.3, 5.1, 15],
        [48.1, 39.4, 5.26, 15],
        [48.1, 39.5, 5.43, 15],
        [48.1, 39.6, 5.55, 14],
        [48.1, 39.7, 5.67, 13],
        [48.1, 39.8, 5.76, 12],
        [48.1, 39.9, 5.82, 11],
        [48.2, 38, 3.17, 5],
        [48.2, 38.1, 3.16, 6],
        [48.2, 38.2, 3.15, 7],
        [48.2, 38.3, 3.16, 7],
        [48.2, 38.4, 3.21, 6],
        [48.2, 38.5, 3.26, 5],
        [48.2, 38.6, 3.42, 6],
        [48.2, 38.7, 3.57, 7],
        [48.2, 38.8, 3.75, 9],
        [48.2, 38.9, 3.96, 11],
        [48.2, 39, 4.17, 12],
        [48.2, 39.1, 4.41, 13],
        [48.2, 39.2, 4.65, 13],
        [48.2, 39.3, 4.81, 13],
        [48.2, 39.4, 4.89, 13],
        [48.2, 39.5, 4.98, 13],
        [48.2, 39.6, 5.1, 13],
        [48.2, 39.7, 5.23, 13],
        [48.2, 39.8, 5.34, 12],
        [48.2, 39.9, 5.45, 11],
        [48.3, 38, 2.96, 1],
        [48.3, 38.1, 2.98, 3],
        [48.3, 38.2, 3, 5],
        [48.3, 38.3, 3.04, 5],
        [48.3, 38.4, 3.09, 4],
        [48.3, 38.5, 3.15, 4],
        [48.3, 38.6, 3.23, 4],
        [48.3, 38.7, 3.32, 5],
        [48.3, 38.8, 3.44, 6],
        [48.3, 38.9, 3.59, 7],
        [48.3, 39, 3.74, 9],
        [48.3, 39.1, 3.98, 9],
        [48.3, 39.2, 4.22, 10],
        [48.3, 39.3, 4.35, 10],
        [48.3, 39.4, 4.38, 10],
        [48.3, 39.5, 4.41, 10],
        [48.3, 39.6, 4.54, 10],
        [48.3, 39.7, 4.66, 10],
        [48.3, 39.8, 4.82, 10],
        [48.3, 39.9, 5, 9],
        [48.4, 38, 2.79, 358],
        [48.4, 38.1, 2.83, 360],
        [48.4, 38.2, 2.88, 2],
        [48.4, 38.3, 2.91, 2],
        [48.4, 38.4, 2.93, 2],
        [48.4, 38.5, 2.95, 3],
        [48.4, 38.6, 3.02, 2],
        [48.4, 38.7, 3.09, 1],
        [48.4, 38.8, 3.14, 1],
        [48.4, 38.9, 3.18, 2],
        [48.4, 39, 3.22, 2],
        [48.4, 39.1, 3.43, 3],
        [48.4, 39.2, 3.64, 4],
        [48.4, 39.3, 3.74, 4],
        [48.4, 39.4, 3.74, 5],
        [48.4, 39.5, 3.73, 5],
        [48.4, 39.6, 3.87, 5],
        [48.4, 39.7, 4.01, 4],
        [48.4, 39.8, 4.21, 5],
        [48.4, 39.9, 4.47, 5],
    ].map((item) => [item[0], item[1], item[2] - 2.7, item[3]]),
};
